import i18next from "i18next";
import { BillingRuleConditionTarget } from "../../../graphql/generated";
import enumLabel from "../enumLabel";

const billingRuleConditionTargetLabel = (
  target: BillingRuleConditionTarget
) => {
  return i18next.t(
    `billingRules:target.${target}`,
    enumLabel(target) || target
  ) as string;
};

export default billingRuleConditionTargetLabel;
