import { Box } from "@mui/material";
import { useState } from "react";
import LoadingOverlay from "../../common/LoadingOverlay";

export default function NationalFuelRateTable() {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <Box style={{ height: "100vh", width: "100vw" }}>
      <LoadingOverlay loading={isLoading} />
      <iframe
        src="https://www.eia.gov/dnav/pet/pet_pri_gnd_a_epd2d_pte_dpgal_w.htm"
        title="WebView"
        style={{ width: "100%", height: "100%", border: "none" }}
        onLoad={() => setIsLoading(false)}
      />
    </Box>
  );
}
