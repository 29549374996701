import React, { useEffect, useState } from "react";
import { Stack, TextField, IconButton, Autocomplete, Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  BusinessEntityType,
  GoodInput,
  GoodInventoryItem,
  GoodProfile,
  GoodUnits,
  ShipmentCommodityType,
} from "../../../../graphql/generated";
import { FormError } from "../../../../redux/slices/Types";
import _ from "lodash";
import { localWeightToKg, tonnage } from "../../../../utils/conversion/weight";
import LocaleProvider from "../../../../providers/LocaleProvider";
import formatWeight from "../../../../utils/labels/formatWeight";
import NumberTextField from "../../../common/NumberTextField";
import goodUnitLabel from "../../../../utils/labels/goodUnitsLabel";
import isLiquidUnit from "../../../../utils/liquid/isLiquidUnit";
import FeatureGuard, { Feature } from "../../../account/Access/FeatureGuard";
import BusinessEntitySelectContainer from "../BusinessEntitySelect";
import { useTranslation } from "react-i18next";

export interface GoodFormProps {
  handleChange: (
    id: string,
    name: string,
    value: string | null | undefined
  ) => void;
  good: GoodInput;
  goodProfiles: GoodProfile[];
  goodInventoryItems: GoodInventoryItem[];
  equivalentGoodProfiles: (GoodProfile & {
    equivalentOf: GoodProfile;
  })[];
  errors?: FormError;
  handleDelete: () => void;
  commodityType?: ShipmentCommodityType | null;
}

export default function GoodForm({
  good,
  goodProfiles,
  equivalentGoodProfiles,
  goodInventoryItems,
  errors,
  handleChange,
  handleDelete,
  commodityType,
}: GoodFormProps) {
  const { t } = useTranslation(["orders", "common"]);
  const [formErrors, setFormErrors] = useState<FormError | undefined>(errors);

  useEffect(() => {
    setFormErrors(errors);
  }, [errors]);

  const onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    event.preventDefault();
    const {
      target: { name, value },
    } = event;

    const err: FormError = {
      formId: formErrors?.formId || "",
      error: _.omit(formErrors?.error, name),
    };

    setFormErrors(err);
    if (name.toLowerCase() === "label") {
      handleChange(good._id, name, value);
    } else if (name === "weight") {
      handleChange(
        good._id,
        "weight",
        value
          ? String(
              localWeightToKg(
                !isNaN(Number.parseFloat(value)) ? Number.parseFloat(value) : 0
              )
            )
          : null
      );
    } else {
      handleChange(
        good._id,
        name,
        String(!isNaN(Number.parseInt(value)) ? Number.parseInt(value) : 0)
      );
    }
  };

  const selectedGoodProfile = goodProfiles.find(
    (gp) => gp._id === good.goodProfileId || gp._id === good._id
  );

  return (
    <Stack direction="row" alignItems="center">
      <Stack direction="row" component="form" spacing={1}>
        <Box flex={2}>
          <Autocomplete
            options={[
              ...goodProfiles.map((gp) => ({
                ...gp,
                equivalentOf: null,
              })),
              ...equivalentGoodProfiles.map((goodProfile) => ({
                ...goodProfile,
                label: `${goodProfile.label} (Equivalent of ${goodProfile.equivalentOf.label})`,
              })),
              ...goodInventoryItems.map((goodInventoryItem) => ({
                ...goodInventoryItem,
                equivalentOf: null,
              })),
            ]}
            freeSolo={!goodProfiles.length}
            fullWidth
            value={good.label}
            groupBy={(goodProfile) =>
              goodProfile.equivalentOf
                ? t("orders:equivalences", "Equivalences")
                : t("orders:commodities", "Commodities")
            }
            getOptionLabel={(goodInventoryItem) => {
              if (typeof goodInventoryItem === "string") {
                return goodInventoryItem;
              } else {
                return goodInventoryItem.label;
              }
            }}
            size="small"
            renderInput={(inputParams) => (
              <TextField
                {...inputParams}
                required
                fullWidth
                name="label"
                label={t("orders:commodityName", "Commodity Name")}
                error={formErrors && formErrors.error["label"] ? true : false}
                helperText={formErrors && formErrors.error["label"]}
                onChange={(event) => {
                  if (goodProfiles.length) {
                    return;
                  }
                  onChange(event);
                }}
              />
            )}
            onChange={(event, value) => {
              if (goodProfiles.length && typeof value === "string") {
                return;
              }
              handleChange(
                good._id,
                "label",
                typeof value === "string" ? value : value?.label || ""
              );
              if (value && typeof value !== "string" && value.weight) {
                handleChange(
                  good._id,
                  "weight",
                  String(localWeightToKg(value.weight))
                );
                if ("unit" in value) {
                  handleChange(good._id, "unit", value.unit);
                  handleChange(good._id, "goodProfileId", value._id);
                } else {
                  handleChange(good._id, "unit", GoodUnits.Item);
                }
              }
              if (value && typeof value !== "string" && value._id) {
                handleChange(good._id, "_id", value._id);
              }
            }}
          />
        </Box>
        <Box flex={1}>
          <TextField
            fullWidth
            label={t("common:quantityWithUnit", {
              unit: goodUnitLabel(good.unit || GoodUnits.Item),
              defaultValue: `Quantity (${goodUnitLabel(
                good.unit || GoodUnits.Item
              )})`,
            })}
            name="quantity"
            value={String(good.quantity)}
            size="small"
            required
            error={formErrors && formErrors.error["quantity"] ? true : false}
            helperText={formErrors && formErrors.error["quantity"]}
            inputProps={{ type: "number", min: 0 }}
            onChange={onChange}
          />
        </Box>
        <Box flex={1}>
          <NumberTextField
            fullWidth
            label={t("common:weightWithUnit", {
              unit: LocaleProvider.getWeightUnit(),
              defaultValue: `Weight (${LocaleProvider.getWeightUnit()})`,
            })}
            name="weight"
            size="small"
            error={formErrors && formErrors.error["weight"] ? true : false}
            helperText={formErrors && formErrors.error["weight"]}
            value={formatWeight(good.weight, false)}
            inputProps={{ type: "number", min: 0 }}
            onChange={onChange}
          />
        </Box>
        <Box flex={1}>
          <TextField
            fullWidth
            label={t("common:tonnage", "Tonnage")}
            name="tonnage"
            disabled
            value={tonnage(good.quantity * (good.weight || 0))}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
        {!good.unit || !isLiquidUnit(good.unit) ? (
          <Box flex={1}>
            <TextField
              fullWidth
              label={t("common.pieces", "Pieces")}
              name="pieces"
              value={String(good.pieces)}
              size="small"
              inputProps={{ type: "number", min: 0 }}
              onChange={onChange}
            />
          </Box>
        ) : null}
        {!good.unit || !isLiquidUnit(good.unit) ? (
          <Box flex={1}>
            <TextField
              fullWidth
              label={t("common.pallet", "Pallet")}
              name="pallet"
              size="small"
              value={String(good.pallet)}
              inputProps={{ type: "number", min: 0 }}
              onChange={onChange}
            />
          </Box>
        ) : null}
        {commodityType === ShipmentCommodityType.Liquid && (
          <FeatureGuard feature={Feature.CommodityManagement}>
            <Box flex={1}>
              <BusinessEntitySelectContainer
                businessEntityType={BusinessEntityType.Supplier}
                value={good.supplierId}
                filterBusinessEntityIds={selectedGoodProfile?.supplierIds || []}
                onChange={(businessEntityId) => {
                  handleChange(good._id, "supplierId", businessEntityId);
                }}
                allowNew={false}
                inputProps={{
                  required: false,
                }}
              />
            </Box>
            <Box flex={1}>
              <TextField
                fullWidth
                label={t("orders:pinCode", "Pin Code")}
                name="pinCode"
                value={good.pinCode}
                size="small"
                onChange={(event) => {
                  handleChange(good._id, "pinCode", event.target.value);
                }}
              />
            </Box>
          </FeatureGuard>
        )}
      </Stack>
      <Stack>
        <IconButton onClick={handleDelete} color="default">
          <DeleteIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
}
