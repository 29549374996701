import { useEffect, useState } from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ReceiverGoodsList from "./ReceiverGoodsList";
import {
  GoodDistributionInput,
  GoodInput,
  ShipmentCommodityType,
  StorageFacility,
} from "../../../../graphql/generated";
import { Breakpoint } from "@mui/material";
import { ShipmentLocationInputData } from "../../../../redux/slices/Types";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Feature, useFeature } from "../../../account/Access/FeatureGuard";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

export interface ReceiverGoodsListContainerProps {
  openModal: boolean;
  width?: Breakpoint;
  shippedGoods: Array<GoodInput>;
  receivedGoods: Array<GoodDistributionInput>;
  shipmentLocation: ShipmentLocationInputData;
  storageFacilities: StorageFacility[];
  onClose: () => void;
  onChange: (receivedGoods: Array<GoodDistributionInput>) => void;
  storageFacilitiesLoading?: boolean;
}

export default function ReceiverGoodsListContainer({
  openModal,
  onClose,
  width,
  shippedGoods,
  receivedGoods: allReceivedGoods,
  shipmentLocation,
  storageFacilities,
  onChange,
  storageFacilitiesLoading,
}: ReceiverGoodsListContainerProps) {
  const { pathname } = useLocation();
  const isPortalAccess = pathname.includes("customer-portal");
  const { isAvailable: isCommodityManagementAvailable } = useFeature(
    Feature.CommodityManagement
  );
  const commodityType = useSelector(
    (state: RootState) => state.shipmentReducer.shipment.commodityType
  );
  const { t } = useTranslation(["common", "orders"]);
  const [receivedGoods, setReceivedGoods] = useState<
    Array<GoodDistributionInput>
  >(shipmentLocation.receivedGoods);
  const [maxWidth] = useState<DialogProps["maxWidth"]>(width);

  const handleCancel = () => {
    onClose();
  };

  useEffect(() => {
    setReceivedGoods(shipmentLocation.receivedGoods);
  }, [shipmentLocation.receivedGoods]);

  return (
    <div>
      <Dialog maxWidth={maxWidth} fullWidth open={openModal}>
        <DialogTitle>
          {t("orders:commoditiesToReceive", "Commodities to receive")}
        </DialogTitle>
        <DialogContent dividers>
          <ReceiverGoodsList
            onChange={(receivedGoods) => {
              setReceivedGoods(receivedGoods);
            }}
            shippedGoods={shippedGoods}
            receivedGoods={allReceivedGoods}
            shipmentLocation={{
              ...shipmentLocation,
              receivedGoods,
              persistedReceivedGoods: shipmentLocation.receivedGoods,
            }}
            storageFacilities={storageFacilities}
            storageFacilitiesLoading={storageFacilitiesLoading}
            bestBuyOptionEnabled={
              !isPortalAccess &&
              isCommodityManagementAvailable &&
              commodityType === ShipmentCommodityType.Liquid
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            color="inherit"
            variant="contained"
            onClick={handleCancel}
          >
            {t("common:cancel", "Cancel")}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              onChange(receivedGoods);
              onClose();
            }}
          >
            {t("common:validate", "Validate")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
