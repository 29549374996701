import { InputAdornment, TextField } from "@mui/material";
import { isNumber } from "lodash";
import { ConditionValueSelectorComponent } from "../ConditionForm";
import LocaleProvider from "../../../../providers/LocaleProvider";
import { useTranslation } from "react-i18next";
import {
  litersToLocalVolume,
  localVolumeToLiters,
} from "../../../../utils/conversion/volume";

const VolumeInput: ConditionValueSelectorComponent = ({ value, onChange }) => {
  const { t } = useTranslation("billingRules");
  return (
    <TextField
      type="number"
      value={isNumber(value) ? litersToLocalVolume(value) || "" : ""}
      onChange={(event) => {
        onChange(localVolumeToLiters(parseFloat(event.target.value) || 0));
      }}
      size="small"
      label={t("target.VOLUME", "Volume")}
      inputProps={{
        type: "number",
        min: 0,
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            {LocaleProvider.getVolumeUnit()}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default VolumeInput;
