import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Stack,
  TextField,
} from "@mui/material";
import {
  AssetLinkingInput,
  GetAllDriverShiftsQuery,
  GetDriverListQuery,
  GetTractorListQuery,
  GetTrailerListQuery,
} from "../../../graphql/generated";
import driverLabel from "../../../utils/labels/driverLabel";
import tractorLabel from "../../../utils/labels/tractorLabel";
import trailerLabel from "../../../utils/labels/trailerLabel";
import LoadingOverlay from "../../common/LoadingOverlay";
import {
  addDays,
  differenceInMinutes,
  endOfDay,
  isEqual,
  isValid,
  startOfDay,
} from "date-fns";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import { shiftAppliesToDriver } from "../../trip-planning/PlanningTool/PlanningCalendar";
import { zonedTimeToUtc } from "date-fns-tz";
import { useEffect } from "react";

export type AssetLinkingFormProps = {
  assetLinking: AssetLinkingInput | null;
  onChange: (assetLinking: AssetLinkingInput) => void;
  drivers: GetDriverListQuery["drivers"]["data"];
  trailers: GetTrailerListQuery["trailers"]["data"];
  tractors: GetTractorListQuery["tractors"]["data"];
  driverShifts: GetAllDriverShiftsQuery["driverShifts"]["data"];
  loading?: boolean;
  selectedDriverId?: string;
  selectedTrailerId?: string;
  selectedTractorId?: string;
  initialStartDate?: Date;
  initialEndDate?: Date;
};

const AssetLinkingForm = ({
  drivers,
  trailers,
  tractors,
  driverShifts,
  assetLinking,
  loading,
  selectedDriverId,
  selectedTrailerId,
  selectedTractorId,
  initialStartDate,
  initialEndDate,
  onChange,
}: AssetLinkingFormProps) => {
  const assetLinkingWithDefaults = {
    ...assetLinking,
    driver: selectedDriverId || assetLinking?.driver,
    trailer: selectedTrailerId || assetLinking?.trailer,
    tractor: selectedTractorId || assetLinking?.tractor,
  };

  useEffect(() => {
    if (initialStartDate || initialEndDate) {
      onChange({
        ...assetLinkingWithDefaults,
        startDate: initialStartDate,
        endDate: initialEndDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation("assets");

  const selectedDriver = drivers.find(
    (driver) => driver._id === assetLinkingWithDefaults.driver
  );

  const driverShiftForInitialStartDate = selectedDriver
    ? driverShifts.find((shift) =>
        shiftAppliesToDriver(
          shift,
          selectedDriver._id,
          selectedDriver.domicileId
        )
      )
    : null;

  const linkingDuration = differenceInMinutes(
    assetLinkingWithDefaults.endDate || new Date(),
    assetLinkingWithDefaults.startDate || new Date()
  );

  let isOneShift = false;
  if (driverShiftForInitialStartDate) {
    let shiftStartDate = new Date(assetLinkingWithDefaults.startDate);
    let shiftEndDate = new Date(assetLinkingWithDefaults.startDate);
    shiftStartDate.setHours(
      driverShiftForInitialStartDate?.startTime.hour || 0,
      driverShiftForInitialStartDate?.startTime.minute || 0
    );
    shiftEndDate.setHours(
      driverShiftForInitialStartDate?.endTime.hour || 0,
      driverShiftForInitialStartDate?.endTime.minute || 0
    );
    shiftStartDate = zonedTimeToUtc(
      shiftStartDate,
      driverShiftForInitialStartDate.domicile?.addressTimezone || "UTC"
    );

    shiftEndDate = zonedTimeToUtc(
      shiftEndDate,
      driverShiftForInitialStartDate.domicile?.addressTimezone || "UTC"
    );

    isOneShift =
      isEqual(shiftStartDate, new Date(assetLinkingWithDefaults.startDate)) &&
      isEqual(shiftEndDate, new Date(assetLinkingWithDefaults.endDate));
  }
  const isOneDay = 1439 <= linkingDuration && linkingDuration <= 1500; // 23:59 hours to 25 hours;
  const isOneWeek = 10079 <= linkingDuration && linkingDuration <= 10140; // 167:59 hours to 169 hours;
  const isPermanent = !assetLinkingWithDefaults.endDate;

  return (
    <Box>
      <LoadingOverlay loading={loading || false} />
      <Stack
        direction="column"
        justifyContent="space-between"
        sx={{
          pt: 2,
        }}
        spacing={1}
      >
        <Autocomplete
          fullWidth
          options={drivers}
          getOptionLabel={(driver) => driverLabel(driver)}
          onChange={(e, driver) =>
            onChange({
              ...assetLinkingWithDefaults,
              driver: driver?._id || null,
            })
          }
          value={
            drivers.find(
              (driver) => driver._id === assetLinkingWithDefaults?.driver
            ) || null
          }
          size="small"
          renderInput={(inputProps) => (
            <TextField
              {...inputProps}
              sx={{ textTransform: "capitalize" }}
              label={t("driver.one", "Driver")}
            />
          )}
          disabled={!!selectedDriverId}
        />
        <Autocomplete
          fullWidth
          options={tractors}
          getOptionLabel={(tractor) => tractorLabel(tractor)}
          onChange={(e, tractor) =>
            onChange({
              ...assetLinkingWithDefaults,
              tractor: tractor?._id || null,
            })
          }
          value={
            tractors.find(
              (tractor) => tractor._id === assetLinkingWithDefaults?.tractor
            ) || null
          }
          size="small"
          renderInput={(inputProps) => (
            <TextField
              {...inputProps}
              sx={{ textTransform: "capitalize" }}
              label={t("tractor.one", "Tractor")}
            />
          )}
          disabled={!!selectedTractorId}
        />
        <Autocomplete
          fullWidth
          options={trailers}
          getOptionLabel={(trailer) => trailerLabel(trailer)}
          onChange={(e, trailer) =>
            onChange({
              ...assetLinkingWithDefaults,
              trailer: trailer?._id || null,
            })
          }
          value={
            trailers.find(
              (trailer) => trailer._id === assetLinkingWithDefaults?.trailer
            ) || null
          }
          size="small"
          renderInput={(inputProps) => (
            <TextField
              {...inputProps}
              sx={{ textTransform: "capitalize" }}
              label={t("trailer.one", "Trailer")}
            />
          )}
          disabled={!!selectedTrailerId}
        />

        <Autocomplete
          fullWidth
          options={trailers}
          multiple={true}
          getOptionLabel={(trailer) => trailerLabel(trailer)}
          onChange={(e, trailers) =>
            onChange({
              ...assetLinkingWithDefaults,
              additionalTrailers: trailers.map((trailer) => trailer._id),
            })
          }
          value={
            trailers.filter((trailer) =>
              assetLinkingWithDefaults.additionalTrailers?.includes(trailer._id)
            ) || null
          }
          size="small"
          renderInput={(inputProps) => (
            <TextField
              {...inputProps}
              sx={{ textTransform: "capitalize" }}
              label={t("pupTrailers.many", "Pup Trailers")}
            />
          )}
          getOptionDisabled={(trailer) =>
            assetLinkingWithDefaults?.trailer === trailer._id
          }
          // disabled={!!selectedTrailerId}
        />

        <Divider />

        <ButtonGroup
          variant="outlined"
          aria-label={t(
            "assetLinkings.predefinedPeriods",
            "Predefined periods"
          )}
        >
          {driverShiftForInitialStartDate ? (
            <Button
              onClick={() => {
                let startDate = initialStartDate
                  ? new Date(initialStartDate)
                  : startOfDay(new Date());
                let endDate = initialStartDate
                  ? new Date(initialStartDate)
                  : startOfDay(new Date());

                const startTime = driverShiftForInitialStartDate.startTime;
                const endTime = driverShiftForInitialStartDate.endTime;

                startDate.setHours(startTime.hour, startTime.minute);
                endDate.setHours(endTime.hour, endTime.minute);

                startDate = zonedTimeToUtc(
                  startDate,
                  driverShiftForInitialStartDate.domicile?.addressTimezone ||
                    "UTC"
                );

                endDate = zonedTimeToUtc(
                  endDate,
                  driverShiftForInitialStartDate.domicile?.addressTimezone ||
                    "UTC"
                );

                onChange({
                  ...assetLinkingWithDefaults,
                  startDate,
                  endDate,
                });
              }}
              variant={isOneShift ? "contained" : "outlined"}
            >
              {t("assetLinkingPeriods.oneShift", "1 Shift")}
            </Button>
          ) : null}
          <Button
            onClick={() => {
              const start = initialStartDate || startOfDay(new Date());
              onChange({
                ...assetLinkingWithDefaults,
                startDate: start,
                endDate: endOfDay(start),
              });
            }}
            variant={isOneDay ? "contained" : "outlined"}
          >
            {t("assetLinkingPeriods.oneDay", "1 Day")}
          </Button>
          <Button
            onClick={() => {
              const start = initialStartDate || startOfDay(new Date());
              onChange({
                ...assetLinkingWithDefaults,
                startDate: start,
                endDate: endOfDay(addDays(start, 6)),
              });
            }}
            variant={isOneWeek ? "contained" : "outlined"}
          >
            {t("assetLinkingPeriods.oneWeek", "1 Week")}
          </Button>
          <Button
            onClick={() => {
              onChange({
                ...assetLinkingWithDefaults,
                startDate: initialStartDate || startOfDay(new Date()),
                endDate: null,
              });
            }}
            variant={isPermanent ? "contained" : "outlined"}
          >
            {t("assetLinkingPeriods.permanent", "Permanent")}
          </Button>
        </ButtonGroup>

        <DateTimePicker
          ampm={false}
          label={t("assetLinkings.startDateAndTime", "Start Date & Time")}
          value={
            assetLinkingWithDefaults.startDate
              ? new Date(assetLinkingWithDefaults.startDate)
              : null
          }
          onChange={(value) => {
            if (!value) {
              onChange({
                ...assetLinkingWithDefaults,
                startDate: null,
              });
              return;
            }
            if (!isValid(value)) {
              return;
            }
            onChange({
              ...assetLinkingWithDefaults,
              startDate: value,
            });
          }}
          renderInput={(params) => <TextField {...params} />}
        />

        <DateTimePicker
          ampm={false}
          label={t("assetLinkings.endDateAndTime", "End Date & Time")}
          value={
            assetLinkingWithDefaults.endDate
              ? new Date(assetLinkingWithDefaults.endDate)
              : null
          }
          onChange={(value) => {
            if (!value) {
              onChange({
                ...assetLinkingWithDefaults,
                endDate: null,
              });
              return;
            }
            if (!isValid(value)) {
              return;
            }
            onChange({
              ...assetLinkingWithDefaults,
              endDate: value,
            });
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </Stack>
    </Box>
  );
};

export default AssetLinkingForm;
