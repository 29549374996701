import LocaleProvider, { Locale } from "../../providers/LocaleProvider";

export function localVolumeToLiters(valueInLocalVolume: number) {
  if (LocaleProvider.getLocale() === Locale.EN_US) {
    return valueInLocalVolume * 3.78541;
  }
  return valueInLocalVolume;
}

export function litersToLocalVolume(valueInLiters: number) {
  if (LocaleProvider.getLocale() === Locale.EN_US) {
    return Number.parseFloat((valueInLiters / 3.78541).toFixed(2));
  }
  return valueInLiters;
}
