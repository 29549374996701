import React from "react";
import { Close } from "@mui/icons-material";
import {
  AppBar,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import NationalFuelRateTable from "./NationalFuelRateTable";
import { useTranslation } from "react-i18next";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type NationalFuelRateTableDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function NationalFuelRateTableDialog({
  isOpen,
  onClose,
}: NationalFuelRateTableDialogProps) {
  const { t } = useTranslation("billingRules");
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen
      TransitionComponent={Transition}
      sx={{
        zIndex: 99999999,
      }}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
          <Typography
            sx={{ ml: 2, flex: 1, color: "white" }}
            variant="h6"
            component="div"
          >
            {t("nationalFuelRateTable", "National Fuel Rate Table")}
          </Typography>
        </Toolbar>
      </AppBar>
      <NationalFuelRateTable />
    </Dialog>
  );
}
